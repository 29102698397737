import "./App.css";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";

import { AuthProvider, useAuth } from "./authentication/context/authContext";
import routes from "./pages/AllRoutes";
import { UserProvider } from "./authentication/context/userContext";
import { useEffect } from "react";
import { getToken, messaging, onMessage } from "./firebase";
import { Avatar, Form, List, notification } from "antd";
import Logo from "./assest/chat/logo.png";

function App() {
 
  const [form] = Form.useForm();
  const { setNewFcmToken } = useAuth();
  const navigate = useNavigate();

  const FcmTokens = process.env.REACT_APP_FCM_TOKEN;

  useEffect(() => {
    const requestPermissionAndToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey: FcmTokens,
          });
          console.log("FCM Token:", token);
          setNewFcmToken(token);

          // Send the token to your server to store it and use it to send notifications
        } else {
          console.error("Permission not granted for notifications");
        }
      } catch (error) {
        console.error("Error getting FCM token:", error);
      }
    };

    requestPermissionAndToken();
  }, [setNewFcmToken, FcmTokens]);

  useEffect(() => {
    const handleMessage = (payload) => {
      console.log("Message received. ", payload);
      notification.open({
        placement: "topRight",
        icon: null,
        message: (
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={Logo} />}
                title={<span>{payload?.notification?.title}</span>}
                description={payload?.notification?.body}
              />
            </List.Item>
          </List>
        ),
      });
      // Handle the notification here (e.g., show a toast, update UI)
    };

    const unsubscribe = onMessage(messaging, handleMessage);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      <div className="dashboard">
        <div className="contentSection">
          <div className="childroute">
            <Routes>
              {routes.map((route, index) => {
                if (route.children) {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    >
                      {route.children.map((childRoute, childIndex) => (
                        <Route
                          key={childIndex}
                          path={childRoute.path}
                          element={childRoute.element}
                        />
                      ))}
                    </Route>
                  );
                }
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
