import React, { useEffect, useState } from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card, message } from 'antd';
import { fetchAfdminProfile } from '../../service/api_services';
import { useAuth } from '../../authentication/context/authContext';
import DefaultImg from "../../assest/chat/user.png"
import UpdateProfile from './UpdateProfile';
const { Meta } = Card;

function ProfileDetails() {
    const { token, logout } = useAuth()
    const [profileData, setProfileData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const showAdminProfile = async () => {
        try {
            const res = await fetchAfdminProfile(token);
            console.log("profile", res);
            if (res.status === 200) {
                const data = res.data.data;
                setProfileData(data);
                setIsLoading(true);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        showAdminProfile();
    }, []);


    return (

        <div className='admin_profile'>

            <Card

                cover={
                    <img
                        alt="example"
                        src={profileData?.profilePic != null ? profileData?.profilePic : DefaultImg}

                    />
                }

            >
                <Meta
                    title={profileData?.fullName}
                    description={profileData?.email}
                />
                <div className="Edit_profile">
                    <UpdateProfile editData={profileData}/>
                </div>
            </Card>
        </div>
    );
}




export default ProfileDetails