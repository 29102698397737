import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Checkbox, Descriptions, Image, List, Popconfirm, Segmented, Select, Space, Statistic, Tabs, Typography, message } from "antd";
import '../../style/vendor.css'
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

import { MdKeyboardBackspace } from "react-icons/md";

import CountUp from 'react-countup';
import { FetchVendorDetails, VendorApproval, VendorRejected } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import PersonDetails from "./PersonDetails";
import WorkingPincode from "./WorkingPincode";
import EmergencyDetails from "./EmergencyDetails";
import VendorDocument from "./VendorDocument";
import WorkedImage from "./WorkedImage";


function DeliveryPartnerDetails() {

    const navigate = useNavigate()
    const { token } = useAuth()
    const { Title } = Typography
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const [selectData, setSelectData] = useState("Personal Details")
    const { id } = useParams()
    const [vendorInfo, setVendorInfo] = useState([])


    const ShowVendorDetails = async () => {
        try {
            await FetchVendorDetails(token, id)
                .then((res) => {
                    console.log(" all vendor data ", res);
                    if (res.status == 200) {
                        setVendorInfo(res.data.data);
                        setIsLoading(true)
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowVendorDetails()
    }, [])

    let vendorData;

    if (!isLoading) {
        vendorData = <div className="loader_main"> <span class="loader"></span></div>; // Show loading indicator while data is loading
    } else {
        if (selectData === "Personal Details") {
            vendorData = <PersonDetails vendorInfo={vendorInfo} />
        } else if (selectData === "Document") {
            vendorData = <VendorDocument vendorInfo={vendorInfo} />
        } else if (selectData === "Working Pincode") {
            vendorData = <WorkingPincode vendorInfo={vendorInfo} />
        } else if (selectData === "Emergency Contact") {
            vendorData = <EmergencyDetails vendorInfo={vendorInfo} />
        } else if (selectData === "Worked Image") {
            vendorData = <WorkedImage vendorInfo={vendorInfo} />
        }
    }
    const { isDocumentVerified, isDoucmentUploaded } = vendorInfo || {};

    // Reject Status
    let rejectStatus;
    if (isDocumentVerified === false) {
        rejectStatus = <Button   disabled shape="round">Rejected</Button>;
    } else if (isDocumentVerified === true && isDoucmentUploaded === true) {
        rejectStatus = <Button type="default"  disabled shape="round">Not Reject</Button>;
    } else if (isDocumentVerified === null && isDoucmentUploaded === false) {
        rejectStatus = <Button type="default"   disabled shape="round" danger>Reject</Button>;
    } else if (isDoucmentUploaded === false) {
        rejectStatus = <Button type="default"  disabled shape="round">Not Upload</Button>;
    } else if (isDoucmentUploaded === true && isDocumentVerified === null) {
        rejectStatus = (
            <Button type="primary" className="reject_vendor" shape="round" danger>
                Reject
            </Button>
        );
    }
    
    // Approval Status
    let approvelStatus;
    if (isDocumentVerified === false || (isDocumentVerified === null && isDoucmentUploaded === false) || isDoucmentUploaded === false) {
        approvelStatus = <Button type="default" disabled shape="round">Approve</Button>;
    } else if (isDocumentVerified === true) {
        approvelStatus = <Button type="default"  disabled shape="round">Approved</Button>;
    } else if (isDoucmentUploaded === true && isDocumentVerified === null) {
        approvelStatus = <Button shape="round" className="vendor_Approved">Approve</Button>;
    }
    

    // let rejectStatus;


    // if (vendorInfo?.isDocumentVerified == false) {
    //     rejectStatus = <Button disabled shape="round">Rejected</Button>;
    // } else if (vendorInfo?.isDocumentVerified == true && vendorInfo?.isDoucmentUploaded == true) {
    //     rejectStatus = <Button disabled shape="round">Not Reject</Button>;
    // } else if (vendorInfo?.isDocumentVerified == null && vendorInfo?.isDoucmentUploaded == false) {
    //     rejectStatus = <Button disabled shape="round" danger>Reject</Button>;
    // } else if (vendorInfo?.isDoucmentUploaded == false) {
    //     rejectStatus = <Button disabled shape="round">Not Upload</Button>;
    // } else if (vendorInfo?.isDoucmentUploaded == true && vendorInfo?.isDocumentVerified == null) {
    //     rejectStatus = <Button type="primary" shape="round" danger >
    //         Reject
    //     </Button>;
    // }

    // //check state 

    // let approvelStatus;


    // if (vendorInfo?.isDocumentVerified == false) {
    //     approvelStatus = <Button disabled shape="round">Approve</Button>;
    // } else if (vendorInfo?.isDocumentVerified == null && vendorInfo?.isDoucmentUploaded == false) {
    //     approvelStatus = <Button disabled shape="round">Approve</Button>;
    // } else if (vendorInfo?.isDocumentVerified == true) {
    //     approvelStatus = <Button disabled shape="round">Approved</Button>;
    // } else if (vendorInfo?.isDoucmentUploaded == false) {
    //     approvelStatus = <Button disabled shape="round">Approve</Button>;
    // } else if (vendorInfo?.isDoucmentUploaded == true && vendorInfo?.isDocumentVerified == null) {
    //     approvelStatus = <Button shape="round" id="Approved">
    //         Approve
    //     </Button>;
    // }


    const cancel = () => {
        message.error("you are enter no")
    }



    //vendor action


    const rejectBody = {
        vendorId: id,
        isDocumentVerified: false
    }

    //accept reject
    const VendorRequestReject = async () => {
        try {
            await VendorRejected(token, rejectBody)
                .then((res) => {
                    console.log(" vendor rejected", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowVendorDetails()
                    } else if (res.status == 200) {
                        message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    message.error(err.message);
                });
        } catch (error) {
            console.log(error);
        }
    };


    //accept approved

    const acceptBody = {
        vendorId: id,
        isDocumentVerified: true
    }

    const VendorRequestApproved = async () => {
        try {
            await VendorApproval(token, acceptBody)
                .then((res) => {
                    console.log(" vendor approve", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowVendorDetails()
                    } else if (res.status == 200) {
                        message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    message.error(err.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_titles">
                        <div className="hear_title">
                            <Title level={4}><Button type="link" onClick={() => navigate(-1)}> <MdKeyboardBackspace className="back_icon" /></Button> Vendor Details</Title>

                        </div>

                    </div>
                    <div className="wallet" onClick={() => navigate(`/dashboard/vendor-wallet/${vendorInfo?._id}`)}>

                        <img src="https://cdn-icons-png.flaticon.com/512/11053/11053377.png" />
                        <h4>₹ <CountUp
                            start={-10}
                            end={vendorInfo?.walletAmount}
                            duration={5}
                        />
                        </h4>
                    </div>

                </div>
                <div className="content">
                    <div className="taps_segment">
                        <Segmented
                            block
                            options={['Personal Details', 'Document', 'Working Pincode', 'Emergency Contact', "Worked Image"]}
                            onChange={(value) => {
                                setSelectData(value);
                                console.log(value); // string
                            }}
                        />

                    </div>
                    <div className="show_selected_data">
                        <Card title={
                            <div className="vendor_details_title">
                                <p>{selectData}</p>
                                <Space>



                                    <Popconfirm
                                        title="Reject the Vendor"
                                        description="Are you sure to reject this Request?"
                                        onConfirm={VendorRequestReject}
                                        onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        {rejectStatus}
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Approve the Vendor"
                                        description="Are you sure to check vendor all document ?"
                                        onConfirm={VendorRequestApproved}
                                        onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        {approvelStatus}
                                    </Popconfirm>
                                </Space>
                            </div>}>
                            {vendorData}
                        </Card>
                    </div>
                </div>


            </div>

        </section>
    );
}





export default DeliveryPartnerDetails