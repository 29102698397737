import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Space,
  Upload,
  message,
} from "antd";
import "../../../style/vendor.css"
import { AddNewPincode } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";



function AddPincode({ ShowAllPincodeList }) {
  const { token } = useAuth()
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  




  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (value) => {
    try {
      await AddNewPincode(token, value)
        .then((res) => {
          console.log(" add new pin", res);
          if (res.status == 201) {
            message.success(res.data.message);
            setOpen(false);
            onReset();

            ShowAllPincodeList()
          } else if (res.status == 200) {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onReset = () => {
    form.resetFields();
  };



  return (
    <div className="modal_section">

      <Button
        type="primary"
        className="primary_btn"
        shape="round"
        onClick={showDrawer}
      >
        Add
      </Button>

      <Drawer
        title="Add New Pincode"
        placement="right"
        width={400}
        onClose={onClose}
        open={open}
      >
        <div className="add_category_form">
          <Form
            form={form}
            layout="vertical"
            name="add-image"
            className="images"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Col md={24}>
              <Form.Item
                label="Pincode"
                name="pincode"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Please Enter only Numbers!',
                  },
                  {
                    required: true,
                    message: "Please Enter Pincode",
                  },
                ]}
              >
                <Input placeholder="Enter Pincode" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Area "
                name="area"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Area",
                  },
                ]}
              >
                <Input placeholder="Enter Area " />
              </Form.Item>
            </Col>
            <div className="model_Submit">
              <Button onClick={onClose} shape="round" danger>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
}

export default AddPincode;