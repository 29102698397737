import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Collapse, Image, Popconfirm, Select, Statistic, Typography, message } from "antd";
import '../../../style/cms.css'
import { useLocation } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
import { FetchFAQList, deleteFAQ } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";
import { Col, Row } from "react-bootstrap";
import AddFaq from "./AddFaq";
// import AddProduct from "./AddProduct";



import { MdCancel } from "react-icons/md";
import { Icons } from "../../../common/icons";

const { Title } = Typography;



function FaqList() {
  const location = useLocation();
  const { token, logout } = useAuth()
  const [faqData, setFaqData] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const ShowAllFAQList = async () => {
    try {
      await FetchFAQList(token)
        .then((res) => {
          console.log(" faq  list ", res);
          if (res.status == 200) {
            setFaqData(res.data.data);
            setIsLoading(true)
          } else if (res.data.code == 283) {
            message.error(res.data.message)
            logout()
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(true)
    }
  };

  useEffect(() => {
    ShowAllFAQList()
  }, [])

  const cancel = () => {
    message.error("Your are select No")
  }

  const DeleteFAQHandler = async (id) => {
    try {
      let body = {
        faqId: id,
      };
      await deleteFAQ(body, token)
        .then((res) => {
          console.log("delete faq ", res);
          if (res.status == 201) {
            message.success(res.data.message);
            ShowAllFAQList();
          }
        })
        .catch((err) => {
          console.log(err.response.msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleIconClick = async (event) => {
    event.stopPropagation();
  };

  return (
    <section className="main_Section">
      <Breadcrumb
        items={[
          {
            title: "Dashboard",
          },
          {
            title: location.pathname,
          },
        ]}
      />
      <div className="content_title">
        <div className="content_head">
          <div className="content_titles">
            <div className="hear_title">
              <Title level={4}>FAQ List</Title>

            </div>


          </div>
          <div className="content_add">
            <AddFaq ShowAllFAQList={ShowAllFAQList} />
          </div>
        </div>
        <div className="content">
          <Row>
            {!isLoading ? <div className="loader_main"> <span className="loader"></span></div> : faqData.map((faq) => (
              <Col md={6}>
                <Collapse defaultActiveKey={['1']} className="mb-2">
                  <Collapse.Panel header={<p><span className="h5">{Icons.faq2}</span> {faq.question}</p>} key={faq._id} extra={

                    <Popconfirm
                      title="Delete the FAQ"
                      description="Are you sure to delete this FAQ?"
                      onConfirm={() => DeleteFAQHandler(faq._id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MdCancel className="delete_icon" onClick={handleIconClick}/>
                    </Popconfirm>
                  }>
                    <p>{faq.answer}</p>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            ))}
          </Row>
        </div>


      </div>

    </section>
  );
}





export default FaqList