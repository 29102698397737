import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Checkbox, Descriptions, Image, List, Segmented, Select, Space, Statistic, Table, Tabs, Tag, Typography } from "antd";
import '../../style/vendor.css'
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";


import { FiArrowUpRight, FiArrowDownLeft } from "react-icons/fi";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,

} from '@ant-design/icons';
import { FetchVendorWallet } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";

function WalletRechargeTransation({ vendorId }) {
  
    const { token } = useAuth()
    const [transationList, setTransationList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [current, setCurrent] = useState(1)



    const columns = [
        {
            title:
                "Payment ID"
            ,
            dataIndex: "razPaymentId",
            key: "razPaymentId",
            render: (_, { razPaymentId }) => (
                <Space>

                    <Button id="diposit__btn" shape="circle" icon={<FiArrowDownLeft className="transIcon" />}></Button>

                    <p>{razPaymentId}</p>
                </Space>
            )
        },
        {
            title:
                "Recharge Date"
            ,
            dataIndex: "rechargeDate",
            key: "rechargeDate",
        },
        {
            title:
                "Recharge Time"
            ,
            dataIndex: "rechargeTime",
            key: "rechargeTime",

        },
        {
            title:
                "Amount"
            ,
            dataIndex: "walletAmount",
            key: "walletAmount",
            render: (_, { walletAmount, status }) => (
                <>

                    <p > + <Tag color={status == "FAILED" ? "red" : "green"} bordered={false}><b>  ₹{walletAmount}</b></Tag></p>
                </>
            )
        },
        {
            title:
                "Payment Methode"
            ,
            dataIndex: "method",
            key: "method",

        },
        {
            title:
                "Status"
            ,
            dataIndex: "status",
            key: "status",
            render: (_, { status }) => (
                <div>
                    {status == "FAILED" ? <Tag icon={<CloseCircleOutlined />} color="red" >{status}</Tag> : <Tag icon={<CheckCircleOutlined />} color="green">{status}</Tag>}
                </div>
            )
        },


    ];

    const ShowVendorWalletTransList = async () => {
        try {
            await FetchVendorWallet(token, current, vendorId)
                .then((res) => {
                    console.log(" wallet transation list ", res);
                    if (res.status == 200) {
                        setTransationList(res.data.data.data);
                        setIsLoading(true)
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowVendorWalletTransList()
    }, [])




    return (
        <section className="main_Section">

            <div className="content_title">

                <div className="content">
                    <div className="vendor_transation_data">
                        {!isLoading ? <div className="loader_main"> <span class="loader"></span></div> :
                            <Table columns={columns} dataSource={transationList} scroll={{ x: true }} />}
                    </div>
                </div>


            </div>

        </section>
    );
}





export default WalletRechargeTransation