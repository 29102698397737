import { MdHome } from "react-icons/md";
import { FaBars, FaTasks, FaUser, FaHandHoldingHeart } from "react-icons/fa";
import { RiAdminFill, RiCoupon4Line } from "react-icons/ri";
import {
  FaCar,
  FaRegQuestionCircle,
  FaBoxes,
  FaRegImages,
} from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { FcFaq } from "react-icons/fc";
import {
  MdOutlineCleaningServices,
  MdOutlineCleanHands,
  MdMergeType,
  MdMiscellaneousServices,
  MdOutlineEventNote,
} from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { FiPackage } from "react-icons/fi";
import {
  FaRegEdit,
  FaUserCheck,
  FaRegCheckCircle,
  FaBoxOpen,
  FaRupeeSign,
  FaHandPointRight,
  FaRegStar,
  FaHandsHelping,
} from "react-icons/fa";
import {} from "react-icons/fa";
import {
  MdOutlinePublishedWithChanges,
  MdOutlineStarHalf,
} from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineLocalOffer } from "react-icons/md";
import {
  MdOutlineStorefront,
  MdOutlineShoppingCart,
  MdOutlineShoppingBag,
  MdFormatListBulleted,
  MdElectricCar,
  MdOutlineInventory2,
  MdAccountBalance,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import { AiOutlineSchedule, AiOutlineRise } from "react-icons/ai";
import { TbDiscount2 } from "react-icons/tb";
import { BsImages } from "react-icons/bs";
import { MdDeliveryDining } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { MdOutlinePushPin } from "react-icons/md";
export const Icons = {
  Logo: <FaCar />,
  type: <MdMergeType />,
  Admin: <MdHome />,
  User: <FaRegUser />,
  Subuser: <FaUserCheck />,
  Cleaner: <MdOutlineCleaningServices />,
  CleanerAllot: <MdOutlineCleanHands />,
  Reallotment: <MdOutlinePublishedWithChanges />,
  discount: <TbDiscount2 />,
  Attend: <FaRegEdit />,
  location: <MdLocationOn />,
  Booking: <FaHandHoldingHeart />,
  Package: <FiPackage />,
  Coupon: <RiCoupon4Line />,
  banner: <BsImages />,
  Rating: <MdOutlineStarHalf />,
  MasterAdmin: <RiAdminFill />,
  delivery: <MdDeliveryDining />,
  Shift: <AiOutlineSchedule />,
  CarType: <MdMergeType />,
  offer: <MdOutlineLocalOffer />,
  Service: <MdMiscellaneousServices />,
  Store: <MdOutlineStorefront />,
  Product: <FaBoxOpen />,
  pin: <MdOutlinePushPin />,
  Category: <FaBoxes />,
  Model: <MdFormatListBulleted />,
  order: <MdOutlineShoppingCart />,

  Brand: <FaRegCheckCircle />,

  VehicleType: <MdElectricCar />,
  Stock: <MdOutlineInventory2 />,
  images: <FaRegImages />,
  Term: <FaRegQuestionCircle />,
  Policy: <MdOutlineEventNote />,
  Role: <FaUser />,
  faq: <FaRegQuestionCircle />,
  faq2: <FcFaq />,
  cms: <HiOutlineClipboardList />,
  about: <FaHandPointRight />,
  refund: <FaRupeeSign />,
  subStar: <FaRegStar />,
  subCoupon: <IoPricetagsOutline />,
  mis: <AiOutlineRise />,
  account: <MdAccountBalance />,
  proAccount: <MdOutlineAccountBalanceWallet />,
  vender: <FaHandsHelping />,
};
