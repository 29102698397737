import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Breadcrumb, Button, Card, Descriptions, Divider, Form, Image, Input, List, Pagination, Select, Statistic, Typography, message } from "antd";
import '../../style/product.css'
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
import { Col, Row } from "react-bootstrap";
import { HiOutlineEye } from "react-icons/hi";
import { FetchOrderList, getorderStatus } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import { AiOutlineUser } from "react-icons/ai";
import AssignPincode from "./AssignPincode";
import { SearchOutlined } from '@ant-design/icons';
import _debounce from 'lodash/debounce';


const { Title } = Typography;





function OrderList() {
    const navigate = useNavigate()
    const { token, logout } = useAuth()
    const location = useLocation();
    const [current, setCurrent] = useState(1)
    const [orderData, setOrderData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(null)
    const [orderStatus, setOrderStatus] = useState("")
    const [searchInput, setSearchInput] = useState("")



    const getOrderStatus = (status) => {
        let ordStatus;
        if (status === "UPCOMING") {
            ordStatus = "#5AB2FF"
        } else if (status === "NOTPICKED") {
            ordStatus = "#FFAF61"
        }
        else if (status === "COMPLETED") {
            ordStatus = "#ACD793"
        } else if (status === "CANCELLED") {
            ordStatus = "#EE4E4E"
        } else if (status === "PENDING") {
            ordStatus = "#B4B4B8"
        } else if (status === "ONGOING") {
            ordStatus = "#3795BD"
        }
        return ordStatus
    }
    const columns = [
        {
            title: 'Order ID',
            dataIndex: '_id',
            key: '_id',
            ellipsis: true,
            render: (_, { _id }) => (
                <span><b>...{_id.substr(-6)}</b></span>
            ),
        },
        {
            title: 'User',
            dataIndex: 'userInfo',
            key: 'userInfo',
            ellipsis: true,
            render: (_, { userInfo }) => (
                <Space>
                    {userInfo?.profilePic != null ? <Image src={userInfo?.profilePic} width={40} height={40} style={{ objectFit: "cover", borderRadius: "100%" }} /> : <Avatar size={40} icon={<AiOutlineUser className="user_icon" />} />}
                    <span>{userInfo?.fullName}</span>
                </Space>
            )

        },

        {
            title: 'Service Pincode',
            dataIndex: 'pincode',
            key: 'pincode',
            ellipsis: true,
            render: (_, { pincode }) => (
                <Tag color="#D862BC">{pincode}</Tag>
            )

        },
        {
            title: 'Slot Date',
            dataIndex: 'serviceDate',
            key: 'serviceDate',
            ellipsis: true,
        },
        {
            title: 'Slot Time',
            dataIndex: 'serviceTime',
            key: 'serviceTime',
            ellipsis: true,
        },

        {
            title: 'No. of Service',
            dataIndex: 'totalServices',
            key: 'totalServices',
            ellipsis: true,
        },
        {
            title: 'Payable Amount',
            dataIndex: 'userPayableAmount',
            key: 'userPayableAmount',
            ellipsis: true,
            render: (_, { userPayableAmount }) => (
                <span>₹ {userPayableAmount}</span>

            ),
        },
        {
            title: 'Platform Charge',
            dataIndex: 'platformAmount',
            key: 'platformAmount',
            ellipsis: true,
            render: (_, { platformAmount }) => (
                <Tag color="#399918">₹ {platformAmount}</Tag>

            ),
        },

        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            fixed: 'right',

            render: (_, { status }) => (
                <Tag color={getOrderStatus(status)}>{status}</Tag>
            ),
        },
        {
            title: 'Action',
            key: 'id',
            fixed: 'right',
            render: (_, { _id, status }) => (
                <Space>
                    <HiOutlineEye className="view_details" onClick={() => navigate(`/dashboard/order-details/${_id}`)} />
                    {status === "NOTPICKED" ? <AssignPincode orderId={_id} ShowAllOrderList={ShowAllOrderList} /> : null}

                </Space>

            ),
        },
    ];

    const onChange = (page) => {
        setCurrent(page);
        setIsLoading(true);
    };

    const ShowAllOrderList = async () => {
        try {
            await FetchOrderList(token, current, searchInput, orderStatus)
                .then((res) => {
                    console.log(" all order list ", res);
                    if (res.status == 200) {
                        setOrderData(res.data.data.data);
                        setTotalPage(res.data.data.totalPage)
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowAllOrderList()
    }, [current,orderStatus,searchInput])


    const handleSearchChange = useCallback(_debounce((value) => {
        setSearchInput(value);
    }, 1000), []);

    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_titles">
                        <div className="hear_title">
                            <Title level={4}>Order List</Title>

                        </div>


                    </div>
                    <div className="content_add">
                        <Space>

                            <Form.Item name="isDocumentUpload">
                                <Select
                                    allowClear
                                    placeholder="Select Status"
                                    optionFilterProp="children"
                                    onChange={(value) => setOrderStatus(value)}
                                    style={{ width: '200px' }}
                                >
                                    {getorderStatus.map((opt) => (
                                        <Select.Option key={opt.key} value={opt.values}>{opt.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="Search Order ID or Name">
                                <Input
                                    style={{ width: '200px' }}
                                    allowClear
                                    placeholder="Search Order ID or Name"
                                    suffix={<SearchOutlined />}
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                />
                            </Form.Item>
                        </Space>
                    </div>
                </div>
                <div className="content">

                    {!isLoading ? <div className="loader_main"> <span className="loader"></span></div> : <Table columns={columns} dataSource={orderData} scroll={{ x: true }}
                     pagination={false}
                        footer={() => <div className="pagination">
                            <Pagination current={current} onChange={onChange} total={totalPage * 10} />
                        </div>} />}


                </div>


            </div>

        </section>
    );
}




export default OrderList