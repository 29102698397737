import { useState, useEffect } from "react";
import { FetchCategoryList, FetchPincodeList } from "../service/api_services";


export const CategoryHook = (token) => {
  const [categoryList, setCategoryList] = useState(null);
  const [defaultId, setDefaultId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const fetchData = async () => {
    try {
      const response = await FetchCategoryList(token, current, searchInput);
      if (response.status == 200) {
        setCategoryList(response.data.data.data);
        setDefaultId(response.data.data.data[0]._id);
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, [token]);
  return { categoryList, loading, defaultId, setDefaultId };
};

export const PincodeHook = (token) => {
  const [pincodeList, setPincodeList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1)

  const fetchData = async () => {
    try {
      const response = await FetchPincodeList(token, current);
      if (response.status == 200) {
        setPincodeList(response.data.data.data);
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, [token]);
  return { pincodeList, loading };
};
