import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Form, Input, Modal, message } from 'antd';
import { MdOutlineSearch } from "react-icons/md";
import "../../style/product.css"
import { ResendOrderToVendor } from '../../service/api_services';
import { PincodeHook } from '../../pages/CustomHooks';
import { useAuth } from '../../authentication/context/authContext';


const AssignPincode = ({ orderId, ShowAllOrderList }) => {
    const { token } = useAuth()
    const { form } = Form.useForm()
    const [pincodeCode, setPincode] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { pincodeList } = PincodeHook(token)
    console.log("2555555pincodeList", pincodeList)




    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const body = {
        userOrderId: orderId,
        resendPincode: pincodeCode,
    }

    const submitHandler = async () => {
        try {
            await ResendOrderToVendor(token, body)
                .then((res) => {
                    console.log(" reassign order", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        setIsModalOpen(false);
                        ShowAllOrderList()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const onFinishFailed = (value) => {
        console.log(value)
    }

    const handleSearch = (value) => {
        console.log(value)
    }
    const handleSelect = (value, id) => {
        console.log(value)
        setPincode(id.id)
        // setVendorId(key)
    }
    return (
        <div className='assign_model'>
            <div className="assign_pin">
                <Button onClick={showModal} shape='round'>Reassign</Button>
            </div>

            <Modal width={400} title="Reassign order to vendor" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <div className="vendor_search_form">

                    <Form layout="vertical" form={form} onFinish={submitHandler} onFinishFailed={onFinishFailed}>
                        <Form.Item label="Search Pincode" name="vendor">
                            <AutoComplete

                                options={pincodeList?.map(option => ({ value: option.pincode, id: option._id }))}
                                suffixIcon={<MdOutlineSearch className='vendor_search' />}
                                onSearch={handleSearch}
                                placeholder="Search Pincode"
                                onSelect={handleSelect}
                                filterOption={pincodeList?.map(option => ({ value: option.pincode.indexOf(option.pincode) !== -1, id: option._id }))}

                            />
                        </Form.Item>
                        <Form.Item>
                            <div className="assign_pin_btn">

                                <Button htmlType='submit' shape='round'>Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};


export default AssignPincode