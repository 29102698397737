import React from "react";
import { Breadcrumb, Button, Card, Image, Select, Statistic, Typography } from "antd";
import '../../../style/cms.css'
import { useLocation } from "react-router-dom";
import { Space, Table, Tag } from 'antd';
// import AddProduct from "./AddProduct";
const { Title } = Typography;



const termData = [
  {
    id: 1,
    title: "privacy policy title 1",
    des: "A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world."
  },
  {
    id: 2,
    title: "privacy policy title 2",
    des: "A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world."
  },
  {
    id: 3,
    title: "privacy policy title  3",
    des: "A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world."
  },
]
const columns = [
  {
    title: 'Titel',
    dataIndex: 'title',
    key: 'title',
    render:(_,{title})=>(
      <span><b>{title}</b></span>
    )
  },
  {
    title: 'Description ',
    dataIndex: 'des',
    key: 'des',
    render: (_, { des }) => (

      <div className="term_des">
         {des}
        
      </div>
    ),
  },

  {
    title: 'Action',
    key: 'id',
    fixed:"right",
    render: (_, id) => (
      <Space size="middle">
        <Button shape="round" type="primary" style={{ background: "#5AC268" }}>Edit</Button>
        <Button shape="round" danger>Delete</Button>
      </Space>
    ),
  },
];



function PrivecyPolicy() {


  const location = useLocation();

  return (
    <section className="main_Section">
      <Breadcrumb
        items={[
          {
            title: "Dashboard",
          },
          {
            title: location.pathname,
          },
        ]}
      />
      <div className="content_title">
        <div className="content_head">
          <div className="content_titles">
            <div className="hear_title">
              <Title level={4}>Privacy & Policy</Title>

            </div>


          </div>
          <div className="content_add">
            {/* <AddProduct /> */}
          </div>
        </div>
        <div className="content">
          <div className="shoo_recent_order">
            <Table columns={columns} dataSource={termData} scroll={{ x: true }}  />
          </div>
        </div>


      </div>

    </section>
  );
}






export default PrivecyPolicy