import React, { useEffect, useState } from "react";
// import "../../style/style.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Spin } from "antd";

function SignUp() {
  const navigate = useNavigate();
  const [isLoding, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  

  return (
    <div className="create">
      <div className="add_admin">
        <Form
          layout="vertical"
          name="basic"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="on"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              value={loginData.email}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              value={loginData.password}
            />
          </Form.Item>
          <Form.Item
            name="Role"
            rules={[
              {
                required: true,
                message: "Please Enter Role!",
              },
            ]}
          >
            <Input
              placeholder="Role"
              value={loginData.email}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 8,
            }}
          >
            <Button
              type="primary"
              disabled={isLoding == true}
            >
              {isLoding == true ? <Spin /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default SignUp;
