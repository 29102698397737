import React, { useState } from "react";
import "./loginpage.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Form, Input, Spin, message } from "antd";
import LoginImg from "../../assest/chat/ac.jpg";
import { Col, Row } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { LoginAdmin } from "../../service/api_services";
import { useAuth } from "../context/authContext";



function LoginPage() {
  
  const [form] = Form.useForm();
  const { login,fcmToken } = useAuth();
  const navigate = useNavigate();


  // const FcmTokens = process.env.REACT_APP_FCM_TOKEN;

  // useEffect(() => {
  //   const requestPermissionAndToken = async () => {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === "granted") {
  //         const token = await getToken(messaging, {
  //           vapidKey: FcmTokens,
  //         });
  //         console.log("FCM Token:", token);
  //         setFcmToken(token);

  //         // Send the token to your server to store it and use it to send notifications
  //       } else {
  //         console.error("Permission not granted for notifications");
  //       }
  //     } catch (error) {
  //       console.error("Error getting FCM token:", error);
  //     }
  //   };

  //   requestPermissionAndToken();
  // }, [setFcmToken, FcmTokens]);

  // useEffect(() => {
  //   const handleMessage = (payload) => {
  //     console.log("Message received. ", payload);
  //     notification.open({
  //       placement: "topRight",
  //       icon: null,
  //       message: (
  //         <List itemLayout="horizontal">
  //           <List.Item>
  //             <List.Item.Meta
  //               avatar={<Avatar src={Logo} />}
  //               title={<span>{payload?.notification?.title}</span>}
  //               description={payload?.notification?.body}
  //             />
  //           </List.Item>
  //         </List>
  //       ),
  //     });
  //     // Handle the notification here (e.g., show a toast, update UI)
  //   };

  //   const unsubscribe = onMessage(messaging, handleMessage);

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);





  const submithandler = async (value) => {
    try {
      await LoginAdmin({ ...value, fcmToken: fcmToken })
        .then((res) => {
          console.log(res);
          if (res.status == 201) {
            login(res.data.data.accessToken);
            localStorage.setItem("token", res.data.data.accessToken);

            onReset()
            message.success(res.data.message);
            navigate("/dashboard");
          } else if (res.data.code == 200) {
            message.error(res.data.message)

          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (value) => {
    console.log(value)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <section>
      <div className="loginSection">
      </div>
      <div className="login">
        <Row>
          <Col md={7}>
            <div className="loginLogo">
              <img src={LoginImg} />
            </div>
          </Col>
          <Col md={5}>
            <div className="loginField">
              <div className="form_title">
                <FaUserCircle className="user_Icons" />
                <h2>Login</h2>
              </div>

              <Form
                form={form}
                onFinishFailed={onFinishFailed}
                onFinish={submithandler}
                layout="vertical"
                name="basic"
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 24,
                }}
                autoComplete="on"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Email"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter Password"

                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 7,
                    span: 12,
                  }}
                >
                  <div className='modal_section'>
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="round"

                    >
                      Login
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              <p>Don't have an account please contact your admin</p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default LoginPage;
