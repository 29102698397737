import { Avatar, Breadcrumb, Button, Form, Image, Input, Pagination, Spin, Typography, message } from "antd";
import React, { useCallback } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Space, Table, Tag } from "antd";
import { useState } from "react";
import { useEffect } from "react";
// import { useAuth } from "../context/authContext";
import { useLocation } from "react-router-dom";
import "../../style/master.css"
import { useAuth } from "../../authentication/context/authContext";
import { FetchUserList } from "../../service/api_services";
import { AiOutlineUser } from "react-icons/ai";
import _debounce from 'lodash/debounce';

const columns = [
    {
        title: "Image",
        dataIndex: "profilePic",
        key: "profilePic",
        render: (_, { profilePic }) => (
            <>
                {profilePic != null ? <Image src={profilePic} width={40} height={40} style={{ objectFit: "cover", borderRadius: "100%" }} /> : <Avatar style={{
                    backgroundColor: '#f8229f',
                }} size={40} icon={<AiOutlineUser className="user_icon" />} />}
            </>

        ),
    },
    {
        title: "Number",
        dataIndex: "mobileNo",
        key: "mobileNo",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Total Bookings",
        dataIndex: "totalBookings",
        key: "totalBookings",
        render: (_, { totalBookings }) => (<Tag color="blue">{totalBookings}</Tag>)
    },
    {
        title: "Created at",
        dataIndex: "addedDate",
        key: "addedDate",
        render: (_, { addedDate }) => (
            <>
                {new Date(addedDate).toLocaleDateString()}
            </>
        )

    },


    {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (_, { status }) => (
            <>{status == "ACTIVE" ? <Tag color="green">{status}</Tag> : <Tag color="red">{status}</Tag>}</>
        ),
    },


];

function UserList() {
    const { token, logout } = useAuth()
    const location = useLocation();
    const [current, setCurrent] = useState(1)
    const [searchInput, setSearchInput] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(null)
    const [userData, setUserData] = useState([])

    const { Title } = Typography;


    const onChange = (page) => {
        setCurrent(page);
        setIsLoading(true);
    };

    const ShowAllOrderList = async () => {
        try {
            await FetchUserList(token, current, searchInput)
                .then((res) => {
                    console.log(" all user list ", res);
                    if (res.status == 200) {
                        setUserData(res.data.data.data);
                        setTotalPage(res.data.data.totalPage)
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowAllOrderList()
    }, [current,searchInput])


    const handleSearchChange = useCallback(_debounce((value) => {
        setSearchInput(value);
    }, 1000), []);

    return (
        <section className="container">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_title">
                        <Title level={4}>User List</Title>
                    </div>
                    <div className="search_filter">
                        <Form.Item name="Search Name & Number">
                            <Input
                                style={{ width: '200px' }}
                                allowClear
                                placeholder="Search Name or  No."
                                suffix={<SearchOutlined />}
                                onChange={(e) => handleSearchChange(e.target.value)}
                            />
                        </Form.Item>
                    </div>

                </div>
                <div className="content">
                    <div className="shoo_recent_order">
                        {!isLoading ? <div className="loader_main"> <span className="loader"></span></div> : <Table columns={columns} dataSource={userData} scroll={{ x: true }} pagination={false}
                            footer={() => <div className="pagination">
                                <Pagination current={current} onChange={onChange} total={totalPage * 10} />
                            </div>} />}
                    </div>
                </div>


            </div>

        </section>
    );
}



export default UserList