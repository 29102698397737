import { Card, message, Statistic } from 'antd';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CountUp from 'react-countup';
import '../dashboard.css'
import OrderImage from "../../../assest/png/order.png"
import VendorImage from "../../../assest/png/partner.png"
import RevenueImage from "../../../assest/png/revenue.png"
import UserImage from "../../../assest/png/usercount.png"
import TotalPincodeOrder from './TotalPincodeOrder';
import TotalOrderStatus from './TotalOrderStatus';
import TotalOrderPichart from './TotalOrderPichart';
import { fetchAfdminProfile, fetchAllCountAnalitics } from '../../../service/api_services';
import { useAuth } from '../../../authentication/context/authContext';



function Dashboard() {
    const formatter = (value) => <CountUp end={value} separator="," />;
    const { logout, token, currentUserData } = useAuth();
    const [countData, setCountData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const showCountData = async () => {
        try {
            const res = await fetchAllCountAnalitics(token);
            console.log("counts data", res);
            if (res.status === 200) {
                const data = res.data.data;
                setCountData(data);

                setIsLoading(true);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (err) {
            message.error(err.message);
        }
    };

    useEffect(() => {
        showCountData();
    }, []);

    const getAdminProfile = async () => {
        try {
            await fetchAfdminProfile(token)
                .then((res) => {
                    console.log("current admin", res)
                    if (res.status == 200) {
                        currentUserData(res.data.data);
                        localStorage.setItem("currentUser", JSON.stringify(res.data.data));

                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    message.error(err.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (token != null) {
            getAdminProfile();
        }
    }, [token]);



    return (
        <div >
            <div className="dashborad_title">
                <Row>
                    <Col md={3}>
                        <Card hoverable>
                            <Statistic title={
                                <h4>Total Order</h4>
                            } value={countData?.totalOrderCount} formatter={formatter} />
                            <img src={OrderImage} />

                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card hoverable>
                            <Statistic title={
                                <h4>Total User</h4>
                            } value={countData?.totalUserCount} formatter={formatter} />
                            <img src={UserImage} />

                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card hoverable>
                            <Statistic title={
                                <h4>Total Vendor</h4>
                            } value={countData?.totalVendorCount} formatter={formatter} />
                            <img src={VendorImage} />

                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card hoverable>
                            <Statistic title={
                                <h4>Total Revenue</h4>
                            } value={countData?.totalRevenueData?.totalPlatformAmount} prefix="₹" formatter={formatter} />
                            <img src={RevenueImage} />
                        </Card>
                    </Col>
                </Row>
            </div>
            {/* <TotalOrderStatus /> */}
            <div className="shw_alalitics">
                <TotalPincodeOrder />
                <Row>
                    <Col md={4}>
                        <TotalOrderStatus />
                    </Col>
                    <Col md={8}>
                        <TotalOrderPichart />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Dashboard