import React, { useEffect, useState } from "react";
import { Badge, Breadcrumb, Button, Card, Divider, Empty, Form, Image, Popconfirm, Select, Space, Statistic, Typography, message } from "antd";
import '../../style/banner.css'
import { useLocation } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import AddBanner from "./AddBanner";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { BannerType, FetchBannerList, deleteExistBanner } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
// import AddProduct from "./AddProduct";
const { Title } = Typography;








function BannerManage() {
    const location = useLocation();
    const { token, logout } = useAuth()
    const [visible, setVisible] = useState(false)
    const [bannerList, setBannerList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [bannerType, setBannerType] = useState("")
    const { Meta } = Card;

    const ShowAllBannerList = async () => {
        try {
            await FetchBannerList(token, bannerType)
                .then((res) => {
                    console.log(" banner  list ", res);
                    if (res.status == 200) {
                        setBannerList(res.data.data);
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowAllBannerList()
    }, [bannerType])

    const deleteBanner = async (id) => {
        try {
            let body = {
                bannerId: id,
            };
            await deleteExistBanner(body, token)
                .then((res) => {
                    console.log("delete banner ", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowAllBannerList();
                    }
                })
                .catch((err) => {
                    console.log(err.response.msg);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const cancel = () => {
        message.error("you are select no")
    }

    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_titles">
                        <div className="hear_title">
                            <Title level={4}> Banner List</Title>
                        </div>
                    </div>
                    <div className="content_add">
                        <Space>

                            <Form.Item name="Banner type">
                                <Select
                                    allowClear
                                    placeholder="Select Banner"
                                    optionFilterProp="children"
                                    onChange={(value) => setBannerType(value)}
                                    style={{ width: '200px' }}
                                >
                                    {BannerType.map((opt) => (
                                        <Select.Option key={opt.key} value={opt.values}>{opt.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item>

                                <AddBanner ShowAllBannerList={ShowAllBannerList} />
                            </Form.Item>
                        </Space>
                    </div>
                </div>
                <div className="content">
                    <div className="banner_list">
                        <Row>
                            {!isLoading ? <div className="loader_main"> <span class="loader"></span></div> : bannerList?.length === 0 ? <Empty /> : bannerList.map((item) => (
                                <Col md={4}>
                                    <div className="banner_card">
                                        <Badge.Ribbon text={item.bannerType} color={item.bannerType != "DASHBOARD" ? "#f8229f" : "cyan"}>
                                            <Card
                                                onMouseEnter={() => setVisible(true)}
                                                onMouseLeave={() => setVisible(false)}
                                                hoverable
                                                cover={<img alt="example" src={item.bannerImage} />} // Replace the image source with your actual image URL

                                            >
                                                <Meta
                                                    title={item.title != null ? item.title : "N/A"}
                                                    description={item.description != null ? item.description : "N/A"}
                                                />
                                                <div className={`${!visible ? "banner_action" : "banner_visible"} `}>

                                                    <Popconfirm
                                                        title="Delete the Banner"
                                                        description="Are you sure to delete this Banner?"
                                                        onConfirm={() => deleteBanner(item._id)}
                                                        onCancel={cancel}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <MdOutlineDelete className="delete_ban_icon" />
                                                    </Popconfirm>
                                                </div>
                                            </Card>
                                        </Badge.Ribbon>

                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </div>
                </div>


            </div>

        </section>
    );
}







export default BannerManage