import React, { useEffect, useState } from "react";
import { Alert, Avatar, Breadcrumb, Button, Card, Descriptions, Divider, Image, List, Tag, Typography, } from "antd";
import '../../style/product.css'
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MdKeyboardBackspace } from "react-icons/md";

import { FetchOrderDetails, } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import { Col, Row } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";



function OrderDetails() {

    const navigate = useNavigate()
    const { token } = useAuth()
    const { id } = useParams()
    const { Title } = Typography
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)

    const [OrderData, setOrderData] = useState([])


    const ShowOrderDetails = async () => {
        try {
            await FetchOrderDetails(token, id)
                .then((res) => {
                    console.log(" order details ", res);
                    if (res.status == 200) {
                        setOrderData(res.data.data);
                        setIsLoading(true)
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowOrderDetails()
    }, [])

    const getOrderStatus = (status) => {
        let ordStatus;
        if (status === "UPCOMING") {
            ordStatus = "#5AB2FF"
        } else if (status === "NOTPICKED") {
            ordStatus = "#FFAF61"
        }
        else if (status === "COMPLETED") {
            ordStatus = "#ACD793"
        } else if (status === "CANCELLED") {
            ordStatus = "#EE4E4E"
        } else if (status === "PENDING") {
            ordStatus = "#B4B4B8"
        }
        return ordStatus
    }
















    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_titles">
                        <div className="hear_title">
                            <Title level={4}><Button type="link" onClick={() => navigate(-1)}> <MdKeyboardBackspace className="back_icon" /></Button> Order Details</Title>

                        </div>

                    </div>


                </div>
                <div className="content">
                    <div className="show_order_data">
                        <Row>
                            <Col md={12}>

                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="User Image">
                                        {OrderData?.userId?.profilePic != null ? <Image src={OrderData?.userId?.profilePic} width={40} height={40} style={{ objectFit: "cover", borderRadius: "100%" }} /> : <Avatar size={40} icon={<AiOutlineUser className="user_icon" />} />}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="User Name">
                                        {OrderData?.userId?.fullName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="User Number">
                                        {OrderData?.userId?.mobileNo}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="User Email">
                                        {OrderData?.userId?.email}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Service Date">
                                        {OrderData?.serviceDate}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Service Time">
                                        {OrderData?.serviceTime}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Servcie Pincode">
                                        {OrderData?.pincode}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Status">
                                        <Tag color={getOrderStatus(OrderData?.status)}>{OrderData?.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>

                            </Col>
                        </Row>
                        <Row>


                            <Col md={6}>
                                <div className="order_item_list">
                                    <Card title={`No. Of Service Booking (${OrderData?.serviceInfo?.length})`} style={{
                                        height: 300,
                                        overflow: 'auto',

                                    }}>

                                        <List
                                            size="small"
                                            itemLayout="horizontal"
                                            dataSource={OrderData?.serviceInfo}
                                            renderItem={(item, index) => (

                                                <List.Item actions={[<span>{item?.selectedValue} . Unit </span>, <span><b>₹ {item.price}</b></span>]}>
                                                    <List.Item.Meta
                                                        avatar={<Image src={item?.serviceId?.serviceImage} width={60} />}
                                                        title={
                                                            <p>{item?.serviceId?.serviceName}</p>
                                                        }
                                                        description={<span>{item.serviceId?.timeDuration} . {item.serviceId?.timeUnit} </span>}

                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </div>
                                <div className="pay_summary">
                                    <Card title="Payment Summary">
                                        <div className="pay_item">
                                            <p><strong>Total Service Amount</strong></p>
                                            <p><strong>₹ {OrderData?.totalServicePrice}</strong></p>
                                        </div>
                                        <div className="pay_item">
                                            <p>Discount {OrderData?.discountId != null ? OrderData?.discountId?.discountValue : null}</p>
                                            {OrderData?.discountAmount != null ?
                                                <Tag color="green" bordered={false}>₹ {OrderData?.discountAmount}</Tag> : 0}
                                        </div>
                                        <Alert showIcon={false} type="warning" message={<div className="pay_item_status">
                                            <p>Gst ({OrderData?.gst}%)</p>
                                            <p>₹ {OrderData?.gstAmount}</p>
                                        </div>} banner />


                                        <Divider dashed />
                                        <div className="pay_item">

                                            <p><b>Payable Total</b></p>
                                            <p><b>₹ {OrderData?.userPayableAmount}</b></p>
                                        </div>
                                        <Alert showIcon={false} type="success" message={<div className="pay_item_status">
                                            <span>Plateform fee  ({OrderData?.platformFee}%)</span>
                                            <span>₹ {OrderData?.platformAmount
                                            }</span>
                                        </div>} banner />

                                    </Card>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Card title="Service Location">
                                    <Descriptions layout="vertical" bordered>
                                        <Descriptions.Item label="Address Type">
                                            {OrderData?.userLocationId?.type}
                                        </Descriptions.Item>
                                        <Descriptions.Item span={2} label="House No/Plot No">
                                            {OrderData?.userLocationId?.houseNo}
                                        </Descriptions.Item>
                                        <Descriptions.Item span={3} label="Area/Locality">
                                            {OrderData?.userLocationId?.area}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Landmark">
                                            {OrderData?.userLocationId?.landmark}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Pincode">
                                            {OrderData?.userLocationId?.pincode}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>

        </section>
    );
}





export default OrderDetails