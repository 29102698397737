import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import "../../style/service.css"
import { CategoryHook } from "../../pages/CustomHooks";
import { InsertNewService, timeDuration } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import { PlusOutlined } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';
import DefaultImg from "../../assest/icon/imageIcon.png"
function AddService({ ShowAllServiceList }) {
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(null)
  const [formData, setFormData] = useState(new FormData());
  const [form] = Form.useForm();
  const [uploder, setUploader] = useState(null)
  const { token } = useAuth()
  const { defaultId, categoryList, setDefaultId } = CategoryHook(token)

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const onFinish = async (value) => {
  
    try {
      formData.append("serviceImage", value.logoFile.file);
      formData.append("serviceName", String(value.serviceName));
      formData.append("categoryId", String(value.categoryId));
      formData.append("price", value.price);
      formData.append("timeDuration", value.timeDuration);
      formData.append("timeUnit", value.timeUnit);
      formData.append("description", JSON.stringify(value.description));

      await InsertNewService(token,formData)
        .then((res) => {
          console.log(" add new service", res);
          if (res.status == 201) {
            message.success(res.data.message);
            setOpen(false);
            onReset();
            setFormData(new FormData());
            ShowAllServiceList()
            setUploader(null)
          }else if(res.status==200){
            message.error(res.data.message);
            setFormData(new FormData());

          }
        })
        .catch((err) => {
          message.error(err.message);
          setFormData(new FormData());

        });
    } catch (error) {
      console.log(error);
      setFormData(new FormData());


    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const validateMinutLength = (_, value) => {
    if (value && value >= 60) {
      return Promise.reject(new Error('Please Enter Valid Minut less then 60 !'));
    }
    return Promise.resolve();
  };

  const validateHoursLength = (_, value) => {
    if (value && value >= 12) {
      return Promise.reject(new Error('Please Enter Valid hours less then 12 !'));
    }
    return Promise.resolve();
  };
  const handlerImage = (file) => {
    console.log(file)
    setUploader(file.fileList)
  }

  const timeDurationHandler = (value) => {
    setDuration(value)
    console.log("duration",value)
  }

  return (
    <div className="modal_section">

      <Button
        shape="round"
        onClick={showDrawer}
      >
        Add
      </Button>

      <Drawer
        title="Add New Service"
        placement="right"
        width={400}
        onClose={onClose}
        open={open}
      >
        <div className="add_category_form">
          <Form
            form={form}
            layout="vertical"
            name="add-image"
            className="images"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Col md={24}>
              <Form.Item
                name="categoryId"
                label="Select Category"
                rules={[
                  {
                    required: true,
                    message: "Please Select Any Category",
                  },
                ]}
              >
                <Select
                  showSearch

                  placeholder="Select Category "
                  optionFilterProp="children"

                >
                  {categoryList?.map((option) => (
                    <Select.Option
                      key={option.categoryName}
                      level={option.categoryName}
                      value={option._id}
                    >
                      {option.categoryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Name"
                name="serviceName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Servcie name",
                  },
                ]}
              >
                <Input placeholder="Enter Serivce Name" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Time Unit"
                name="timeUnit"

              >
                <Select
                  showSearch
                  placeholder="Select Category "
                  optionFilterProp="children"
                  onChange={timeDurationHandler}
                
                >
                  {timeDuration?.map((option) => (
                    <Select.Option
                      key={option.key}
                      level={option.values}
                      value={option.values}
                    >
                      {option.values}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {duration!="MINUTS" ?  <Col md={24}>
              <Form.Item
                label="Time in Hour"
                name="timeDuration"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Duration",
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Please Enter only Numbers!',
                  },
                  { validator: validateHoursLength }
                ]}
              >
                <Input placeholder="Enter Time Duration" />
              </Form.Item>
            </Col>:
            <Col md={24}>
              <Form.Item
                label="Time in Minut"
                name="timeDuration"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Duration",
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Please Enter only Numbers!',
                  },
                  { validator: validateMinutLength }
                ]}
              >
                <Input placeholder="Enter Category Name" />
              </Form.Item>
            </Col> }

            <Col md={24}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Category name",
                  },
                ]}
              >
                <Input placeholder="Enter Category Name" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.List
                label="Enter Multiple Description"
                name="description"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(new Error('At least 1 description'));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (

                      <Form.Item
                        label={index === 0 ? 'Description' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Row>
                          <Col md={22}>
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please Enter Description's  or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <Input.TextArea
                                placeholder="Description's"

                              />
                            </Form.Item>
                          </Col>
                          <Col md={2}>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Col>
                        </Row>


                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          width: '100%',
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add Description
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            <Col md={24}>
              <Form.Item name="logoFile" label={uploder?.length != 1 ? "Choose  Image" : "If you want to choose other image, remove exit Image"}>
                <Upload
                  multiple={false}
                  listType="picture-circle"
                  beforeUpload={() => false} // To prevent automatic upload
                  accept="jpg/jpeg/pdf"
                  onChange={handlerImage}
                >
                  {uploder?.length != 1 ? <img src={DefaultImg} className="image_hold" /> : null}
                </Upload>
              </Form.Item>
            </Col>
            <div className="model_Submit">
              <Button onClick={onClose} shape="round" danger>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
}

export default AddService;