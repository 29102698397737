import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Space,
  Upload,
  message,
} from "antd";
import "../../../style/vendor.css"
import { InsertNewCategory } from "../../../service/api_services";
import { useAuth } from "../../../authentication/context/authContext";
// import { useAuth } from "../../authentication/context/authContext";
// import { InsertNewCategory } from "../../service/api_services";


function AddCategory({ ShowAllCategoryList }) {
  const { token } = useAuth()
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [form] = Form.useForm();
  const [uploder, setUploader] = useState(null)




  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (value) => {
    try {
      formData.append("categoryImage", value.logoFile.file);
      formData.append("categoryName", value.categoryName);

      await InsertNewCategory(token, formData)
        .then((res) => {
          console.log(" add new category", res);
          if (res.status == 201) {
            message.success(res.data.message);
            setOpen(false);
            onReset();
            setFormData(new FormData());
            ShowAllCategoryList()
            setUploader(null)
          } else if (res.status == 200) {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setFormData(new FormData());

        });
    } catch (error) {
      console.log(error);
      setFormData(new FormData());

    }
  };

  const onReset = () => {
    form.resetFields();
  };
  const handlerImage = (file) => {
    console.log(file)
    setUploader(file.fileList)
  }


  return (
    <div className="modal_section">

      <Button
        type="primary"
        className="primary_btn"
        shape="round"
        onClick={showDrawer}
      >
        Add
      </Button>

      <Drawer
        title="Add New Category"
        placement="right"
        width={400}

        onClose={onClose}
        open={open}
      >
        <div className="add_category_form">
          <Form
            form={form}
            layout="vertical"
            name="add-image"
            className="images"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Col md={24}>
              <Form.Item
                label="Category Name"
                name="categoryName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Category name",
                  },
                ]}
              >
                <Input placeholder="Enter Category Name" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item name="logoFile" label={uploder?.length != 1 ? "Choose  Image" : "If you want to choose other image, remove exit Image"}>
                <Upload
                  multiple={false}
                  listType="picture-circle"
                  beforeUpload={() => false} // To prevent automatic upload
                  accept="jpg/jpeg/pdf"
                  onChange={handlerImage}
                >
                  {uploder?.length != 1 ? <img src="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg" className="image_hold" /> : null}
                </Upload>
              </Form.Item>
            </Col>
            <div className="model_Submit">
              <Button onClick={onClose} shape="round" danger>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
}

export default AddCategory;