import { Avatar, Button, Descriptions, Empty } from 'antd'
import React from 'react'
function WorkingPincode({ vendorInfo }) {
    return (
        <div className='personal_details'>
            {/* <AssignPincode /> */}
            <Descriptions bordered layout="vertical">

                {vendorInfo?.workingPinCode && vendorInfo?.workingPinCode.length==0 ?<Descriptions.Item label="Pincode"><Empty />  </Descriptions.Item> : vendorInfo?.workingPinCode &&vendorInfo?.workingPinCode.map((item,index) => <Descriptions.Item label={`Pincode - ${index+1}`}>{item} </Descriptions.Item>)}

            </Descriptions>
        </div>
    )
}


export default WorkingPincode