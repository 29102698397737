import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Statistic, Segmented, Tabs, Tag, Typography } from "antd";
import '../../style/vendor.css'
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

import { MdKeyboardBackspace } from "react-icons/md";
import CountUp from 'react-countup';

import WalletRechargeTransation from "./WalletRechargeTransation";
import WalletDedectTransation from "./WalletDedectTransation";

function WalletHistory() {
    const navigate = useNavigate()
    const { Title } = Typography
    const location = useLocation();
    const { id } = useParams()


    const [selectData, setSelectData] = useState("Recharge Transaction")



    let transationData;


    if (selectData === "Recharge Transaction") {
        transationData = <WalletRechargeTransation vendorId={id} />
    } else if (selectData === "Deduction Transaction") {
        transationData = <WalletDedectTransation vendorId={id} />

    }
   
    return (
        <section className="main_Section">
            <Breadcrumb
                items={[
                    {
                        title: "Dashboard",
                    },
                    {
                        title: location.pathname,
                    },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="content_titles">
                        <div className="hear_title">
                            <Title level={4}><Button type="link" onClick={() => navigate(-1)}> <MdKeyboardBackspace className="back_icon" /></Button>Vendor Wallet</Title>
                        </div>
                    </div>
                    <div className="wallet">

                        <img src="https://cdn-icons-png.flaticon.com/512/11053/11053377.png" />
                        <h4>₹ <CountUp
                            start={-100}
                            end={2500}
                            duration={5}
                        />
                        </h4>
                    </div>

                </div>
                <div className="content">

                    <div className="vendor_transation_data">
                        <Card title={<div className="wallet_title"><p>Wallet Transaction </p><p>Mr. Rahul </p></div>}>
                            <div className="taps_segment">
                                <Segmented
                                    block
                                    options={['Recharge Transaction', 'Deduction Transaction']}
                                    onChange={(value) => {
                                        setSelectData(value);
                                        console.log(value); // string
                                    }}
                                />

                            </div>
                            <div className="mt-2">
                                {transationData}
                            </div>
                        </Card>
                    </div>
                </div>


            </div>

        </section>
    );
}





export default WalletHistory